import styled from 'styled-components';

const PrivacyContainer = styled.div`
  width: 100%;
  display: block;
  padding: 0px 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 768px) {
    width: 100%;
    position: relative;
    bottom: 0px;
    left: 0px;
    margin: 0px;
    padding: 40px 20px;
  }
`;

export default PrivacyContainer;
