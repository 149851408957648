import styled from 'styled-components';

const Container = styled.input`
  width: 100%;
  display: block;
  height: 34px;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 6px 12px;
  margin: 0px;
`;

export default Container;
