import React, {Component} from 'react';

// Components
import Home from '../../../home/components/Home/Home';
import Page from '../../../layout/components/Page/Page';

// Containers
// import CookieContainer from '../../../cookie/containers/CookieContainer/CookieContainer';

// faq pages
// import DataIncidentPage from '../../../faq/pages/DataIncidentPage/DataIncidentPage';
// import FaqPage from '../../../faq/pages/FaqPage/FaqPage';

// faq routes
// import dataIncidentRoute from '../../../faq/pages/DataIncidentPage/route';
// import faqRoute from '../../../faq/pages/FaqPage/route';

// react router
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {ScrollContext} from 'react-router-scroll-4';

class App extends Component {
  render() {
    return (
      <Page>
        <BrowserRouter>
          <ScrollContext>
            <Switch>
              {/* <Route path={faqRoute()} exact>
                <FaqPage />
              </Route>
              <Route path={dataIncidentRoute()} exact>
                <DataIncidentPage />
              </Route> */}
              <Route>
                <Home />
              </Route>
            </Switch>
          </ScrollContext>
        </BrowserRouter>
        {/* <CookieContainer /> */}
      </Page>
    );
  }
}

export default App;
