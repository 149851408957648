import styled from 'styled-components';

const Error = styled.div`
  width: 100%;
  margin-top: 20px;
  color: white;
  background: #ff3600;
  padding: 10px;
  border-radius: 5px;
  font-size: 15px;
`;

export default Error;
