import styled from 'styled-components';
import {transitions} from 'polished';

const Watch = styled.div`
  position: absolute;
  bottom: 50px;
  right: 50px;
  color: white;
  font-family: 'ProximaNova';
  font-weight: 300;
  font-size: 20px;
  text-transform: uppercase;
  pointer-events: none;
  display: flex;
  align-items: center;
  opacity: ${({visible}) => (visible ? 1 : 0)};
  transform: ${({visible}) => (visible ? 'none' : 'translateX(50px)')};
  ${transitions('all 1.5s cubic-bezier(0.21, 0, 0.18, 1)')};

  @media (max-width: 768px) {
    display: none;
    font-size: 14px;
    bottom: 0px;
    right: 10px;
  }
`;

export default Watch;
