import styled from 'styled-components';
import {transitions} from 'polished';

const VideoPlayer = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: black;
  transform: ${({visible, fullScreen}) =>
    fullScreen ? 'scale(1)' : visible ? 'scale(1.36)' : 'scale(2)'};
  ${transitions('transform 1s cubic-bezier(0.21, 0, 0.18, 1)')};

  @media (max-width: 768px) {
    transform: scale(1);
    height: initial;
    object-fit: contain;
  }
`;

export default VideoPlayer;
