import styled from 'styled-components';
// import {transitions} from 'polished';

// Components
import ActualLogo from '../../../../layout/components/Logo/Logo';

const Logo = styled(ActualLogo)`
  height: 30px;
  margin: 50px 0px 0px 50px;
  ${
    '' /* opacity: ${({animate}) => (animate ? 1 : 0)};
  transform: ${({animate}) => (animate ? 'none' : 'translateX(-50px)')};
  ${transitions('all 1.5s cubic-bezier(0.21, 0, 0.18, 1)')}; */
  }

  @media (max-width: 768px) {
    height: 20px;
    margin: 20px 0px 0px 20px;
  }
`;

export default Logo;
