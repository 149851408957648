import React, {Component, Fragment} from 'react';

// Styles
import styled, {createGlobalStyle} from 'styled-components';
import {clearFix} from 'polished';

// Colors
import {black} from '../../lib/colors';

const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100%;
    min-height: 100vh;
    padding: 0px;
    margin: 0px;
    display: block;
    overflow: visible;
    font-family: ProximaNova;
    font-size: 14px;
    color: ${black};
    background: white;
    -webkit-font-smoothing: antialiased;
  }

  * {
    box-sizing: border-box;
    font-family: ProximaNova;
    outline: none;
  }

  a {
    text-decoration: none;
  }

  button, button:active, button:focus {
    outline: none;
  }

  ::-webkit-scrollbar {
    display: none;
    visibility: hidden;
    width: 0px;
    height: 0px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    display: none;
    visibility: hidden;
    width: 0px;
    height: 0px;
    background: transparent;
  }
`;

const Page = styled.div`
  width: 100%;
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  ${'' /* overflow-x: hidden; */}
  ${clearFix()};
`;

class Container extends Component {
  render() {
    const {children, ...props} = this.props;
    return (
      <Fragment>
        <GlobalStyle />
        <Page {...props}>{children}</Page>
      </Fragment>
    );
  }
}

export default Container;
