import styled from 'styled-components';

const PrivacySettings = styled.div`
  position: fixed;
  left: 50px;
  bottom: 50px;
  user-select: none;
  cursor: pointer;

  @media (max-width: 768px) {
    position: static;
    width: 100%;
    padding: 0px 20px 20px 20px;
  }
`;

export default PrivacySettings;
