import styled from 'styled-components';

const Close = styled.div`
  width: 25px;
  height: 25px;
  position: absolute;
  right: 0px;
  top: 0px;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:before {
    height: 25px;
    position: absolute;
    transform: rotate(-45deg);
    content: '';
    border-left: 5px solid #a4a4a4;
    left: 10px;
    top: 0;
  }

  &:after {
    height: 25px;
    position: absolute;
    transform: rotate(45deg);
    content: '';
    border-left: 5px solid #a4a4a4;
    left: 10px;
    top: 0;
  }
`;

export default Close;
