import styled from 'styled-components';

const Container = styled.div`
  width: 44vw;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default Container;
