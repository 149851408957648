export const white = '#ffffff';
export const red = '#FF3600';
export const grey = '#E9EDEF';
export const darkGrey = '#8EA1AC';
export const darkerGrey = '#818E94';
export const black = '#263238';
export const lightBlue = '#F4F7FC';
export const blue = '#04A9F4';
export const green = '#50CE56';
export const teal = '#1EF193';
export const purple = '#9219F1';
export const pink = '#FF2DB0';
export const orange = '#FEB94A';
export const yellow = '#FFCE06';
