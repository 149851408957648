// axios
import axios from 'axios';

// layout components
import Input from '../../../layout/components/Input/Input';
import Modal from '../../../layout/components/Modal/Modal';

// local components
import Button from './components/Button';
import ButtonContainer from './components/ButtonContainer';
import Error from './components/Error';
import Form from './components/Form';
import PrivacySettings from './components/PrivacySettings';
import Saved from './components/Saved';

// react
import React, {Component, Fragment} from 'react';

class PrivacyChoice extends Component {
  state = {
    email: '',
    error: null,
    firstName: '',
    lastName: '',
    sending: false,
    submitted: false,
    visible: false,
  };

  show = () => this.setState({visible: true});

  hide = () => this.setState({visible: false});

  change = (key) => (value) => this.setState({[key]: value, error: null});

  submit = async () => {
    const {email, firstName, lastName, sending} = this.state;

    if (sending) return;

    const error = [email, firstName, lastName].some((v) => !v.trim().length);

    if (error) return this.setState({error: 'validation'});

    this.setState({sending: true});

    try {
      await axios.post(
        'https://george-api-production.drivesally.com/api/core/data_sharing_optout/',
        {
          email,
          first_name: firstName,
          last_name: lastName,
        }
      );
      this.setState({
        email: '',
        firstName: '',
        lastName: '',
        sending: false,
        error: null,
        submitted: true,
        visible: false,
      });
    } catch (error) {
      this.setState({error: 'submit', sending: false});
    }
  };

  render() {
    const {email, error, firstName, lastName, sending, submitted, visible} =
      this.state;
    return (
      <Fragment>
        <PrivacySettings onClick={this.show}>
          Your Privacy Choices
        </PrivacySettings>
        <Modal
          visible={visible}
          onClose={this.hide}
          title="YOUR PRIVACY CHOICES"
        >
          <h3>DO NOT SELL OR SHARE MY PERSONAL INFORMATION</h3>
          <p>
            If you have not yet opted out of sale/sharing of your personal
            information, please submit the form below.
          </p>

          <p>
            The information you provide below, if needed, will facilitate your
            request to opt-out of sale/sharing. If you do not provide the
            information requested below, we may not be able to identify you and
            process your request to opt-out. Any information you provide below
            will not be used, disclosed, or retained for any purpose other than
            processing the request to opt-out of sale/sharing.
          </p>

          <p>
            For more information about our privacy practices, please review our
            Privacy Policy.
          </p>

          <p>
            If you are logged into your Drive Sally account and have already
            been opted-out of sale/sharing of your personal information, the
            form will not appear.
          </p>

          <p>
            If you do not have a Drive Sally account or if you are not logged
            into your Drive Sally account, your request to opt-out of
            sale/sharing will be linked to your browser identifier only and not
            linked to any account information because the connection between
            your browser and the account is not known to us.
          </p>
          <h3>OPT-OUT PREFERENCE SIGNAL (GLOBAL PRIVACY CONTROL)</h3>
          <p>
            You may use an Opt-Out Preference Signal, such as the Global Privacy
            Control (GPC), to opt-out of the sale/sharing of your personal
            information.
          </p>

          <p>
            If you do not have a Drive Sally account or if you are not logged
            into your Drive Sally account, your request to opt-out of
            sale/sharing will be linked to your browser identifier only and not
            linked to any account information because the connection between
            your browser and the account is not known to us.
          </p>

          <p>
            If you would like us to make the connection between your browser and
            your account, and you have not yet opted out of sale/sharing your
            personal information, please submit the form below.
          </p>
          {submitted ? (
            <Saved>Settings stored.</Saved>
          ) : (
            <Fragment>
              <h3>No Opt-Out Preference Signal has been detected.</h3>
              <Form>
                <Input
                  value={firstName}
                  onChange={this.change('firstName')}
                  placeholder="First Name"
                />
                <Input
                  value={lastName}
                  onChange={this.change('lastName')}
                  placeholder="Last Name"
                />
                <Input
                  value={email}
                  onChange={this.change('email')}
                  placeholder="Email"
                  type="email"
                />
              </Form>
              {!!error && (
                <Error>
                  {error === 'submit'
                    ? 'Form could not be submitted. Please try again later.'
                    : 'Please fill out all fields.'}
                </Error>
              )}
              <ButtonContainer>
                <Button disabled={sending} onClick={this.submit}>
                  {sending ? 'Sending...' : 'Submit'}
                </Button>
              </ButtonContainer>
            </Fragment>
          )}
        </Modal>
      </Fragment>
    );
  }
}

export default PrivacyChoice;
