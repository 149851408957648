import styled from 'styled-components';

const Content = styled.div`
  width: 100%;
  max-width: 800px;
  border-radius: 10px;
  background: white;
  padding: 20px;
`;

export default Content;
