// local components
import Container from './components/Container';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const Input = ({onChange, value, ...props}) => (
  <Container
    value={value}
    type="text"
    onChange={(e) => onChange(e.target.value)}
    {...props}
  />
);

Input.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default Input;
