import styled, {css} from 'styled-components';
import {transitions} from 'polished';

const Container = styled.div`
  width: ${({aside, fullScreen}) => (!fullScreen && aside ? '56vw' : '100vw')};
  height: 100vh;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 100;
  overflow: hidden;
  cursor: pointer;

  ${({hoverable}) =>
    hoverable
      ? css`
          &:hover {
            width: 66vw;
          }
        `
      : ''}

  ${transitions('all .5s cubic-bezier(0.21, 0, 0.18, 1)')};

  @media (max-width: 768px) {
    width: 100vw;
    height: initial;
    ${
      '' /* height: ${({aside, fullScreen}) =>
      !fullScreen && aside ? '270px' : '100vh'}; */
    }
    ${'' /* height: ${({fullScreen}) => (!fullScreen ? '300px' : '100vh')}; */}
    ${'' /* bottom: 0px; */}
    position: relative;

    ${({hoverable}) =>
      hoverable
        ? css`
            &:hover {
              width: 100vw;
            }
          `
        : ''}
  }
`;

export default Container;
