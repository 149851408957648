import React, {Component} from 'react';
import {isMobile} from 'react-device-detect';

// Components
import Container from './components/Container';
// import Fancy from './components/Fancy';
import Heading from './components/Heading';
import Link from './components/Link';
import Links from './components/Links';
import Logo from './components/Logo';
import Privacy from './components/Privacy';
import PrivacyContainer from './components/PrivacyContainer';
import Video from '../../../video/components/Video/Video';
// import HomeLoader from '../HomeLoader/HomeLoader';

// faq routes
// import dataIncidentRoute from '../../../faq/pages/DataIncidentPage/route';
// import faqRoute from '../../../faq/pages/FaqPage/route';

// privacy components
import PrivacyChoice from '../../../privacy/components/PrivacyChoice/PrivacyChoice';

class Home extends Component {
  state = {
    videoAnimation: null,
    fullScreen: false,
  };

  video = null;
  animated = false;
  lastFullScreenChange = 0;

  wait = (time = 0) => new Promise((res) => setTimeout(res, time));

  animate = async () => {
    this.animated = true;
    await this.wait(700);
    this.setState({videoAnimation: 'showVideo'});
    await this.wait(1200);
    this.setState({videoAnimation: 'rest'});
    await this.wait(1500);
    this.setState({videoAnimation: 'done'});
  };

  setupVideoEvents = (video) => {
    if (!!this.video) return;
    this.video = video;
    // setTimeout(() => {
    //   this.setState({videoAnimation: 'done'});
    // }, 1000);

    // return;
    video.volume = 0;
    if (isMobile) return this.setState({videoAnimation: 'done'});
    this.setState({videoAnimation: 'done'});
    video.oncanplaythrough = () => {
      if (this.animated) return;
      video.play();
      // this.animate();
    };
  };

  onFullScreen = () => {
    const {videoAnimation, fullScreen} = this.state;
    if (videoAnimation !== 'done' || !this.video) return;
    if (isMobile) return;
    this.lastFullScreenChange = new Date().getTime();
    this.video.muted = false;
    this.animateVolume({
      duration: 1000,
      interval: 100,
      increase: !fullScreen,
      lastChange: this.lastFullScreenChange,
      volume: !fullScreen ? 0 : 1,
    });
    this.setState({fullScreen: !fullScreen});
  };

  animateVolume = async ({
    duration,
    interval,
    increase,
    lastChange,
    volume,
  } = {}) => {
    const volumeStep = interval / duration;
    const newVolume = increase ? volume + volumeStep : volume - volumeStep;
    if (
      newVolume <= 0 ||
      newVolume >= 1 ||
      lastChange !== this.lastFullScreenChange
    )
      return;
    this.video.volume = newVolume;
    await this.wait(interval);
    this.animateVolume({
      duration,
      interval,
      increase,
      lastChange,
      volume: newVolume,
    });
  };

  links = () => [
    {
      url: 'https://join.drivesally.com',
      label: 'Rideshare Vehicle Rentals',
    },
    {
      url: 'https://yellow.drivesally.com',
      label: 'Yellow Taxi Rentals',
    },
    {
      url: 'https://toppers.drivesally.com',
      label: 'Digital Topper Advertising',
    },
    {
      url: 'https://lsg.drivesally.com',
      label: 'Vehicle Service and Repairs',
    },
    // {
    //   url: 'https://boards.greenhouse.io/drivesally',
    //   label: 'Careers',
    // },
    // {
    //   url: 'https://tesla.drivesally.com',
    //   label: 'Tesla Taxi Tracker',
    // },
  ];

  render() {
    const {videoAnimation, fullScreen} = this.state;
    return (
      <Container>
        {/* <Fancy /> */}
        <Logo animate={['rest', 'done'].includes(videoAnimation) ? 1 : 0} />
        <Heading>
          The industry standard for electric vehicle rentals for rideshare,
          taxi, and last-mile.
        </Heading>
        <Links>
          {this.links().map((link, index) => (
            <Link
              key={link.url}
              href={link.url}
              visible={['rest', 'done'].includes(videoAnimation)}
              pause={index * 100}
            >
              {link.label}
            </Link>
          ))}
        </Links>
        <Video
          animation={videoAnimation}
          fullScreen={fullScreen}
          setupVideoEvents={this.setupVideoEvents}
          onFullScreen={this.onFullScreen}
        />
        <PrivacyContainer>
          <Privacy
            href="https://boards.greenhouse.io/drivesally"
            target="_blank"
            visible={!fullScreen}
          >
            Careers
          </Privacy>
          <Privacy
            href="https://privacy.drivesally.com"
            target="_blank"
            visible={!fullScreen}
          >
            Privacy policy
          </Privacy>
          {/* <Privacy
            href={dataIncidentRoute()}
            target="_blank"
            visible={!fullScreen}
          >
            Notice of data incident
          </Privacy>
          <Privacy href={faqRoute()} target="_blank" visible={!fullScreen}>
            Data Security FAQs
          </Privacy> */}
        </PrivacyContainer>
        <PrivacyChoice />
        {/* <HomeLoader /> */}
      </Container>
    );
  }
}

export default Home;
