import styled from 'styled-components';

const Heading = styled.div`
  width: 100%;
  display: block;
  margin: 50px 0px 0px 0px;
  padding: 0px 50px;
  font-size: 30px;
  font-family: 'MavenPro', sans-serif;
  color: #263238;
  letter-spacing: -0.02em;
  margin-left: -0.075em;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 24px;
    padding: 0px 20px;
    margin: 30px 0px 10px 0px;
  }
`;

export default Heading;
