// local components
import Close from './components/Close';
import Container from './components/Container';
import Content from './components/Content';
import Text from './components/Text';
import Title from './components/Title';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const Modal = ({children, onClose, title, visible}) => (
  <Container visible={visible}>
    <Content>
      <Title>
        {title}
        <Close onClick={onClose} />
      </Title>
      <Text>{children}</Text>
    </Content>
  </Container>
);

Modal.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  title: PropTypes.node,
  visible: PropTypes.bool,
};

export default Modal;
