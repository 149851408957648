import React, {Component} from 'react';
import {Cross as Hamburger} from 'hamburger-react';
import {isMobile, isMobileOnly} from 'react-device-detect';

// Poster
// import poster from '../../assets/poster.jpg';

// Components
import Container from './components/Container';
// import Loader from '../../../layout/components/Loader/Loader';
// import Overlay from './components/Overlay';
import VideoPlayer from './components/VideoPlayer';
import Watch from './components/Watch';

// Video
const fullVideoSrc =
  'https://sally-dashboard-assets.s3.amazonaws.com/video/full.mp4';
const mobileVideoSrc =
  'https://sally-dashboard-assets.s3.amazonaws.com/video/mobile.mp4';
// import src from '../../assets/full.mp4';

class Video extends Component {
  render() {
    const {animation, fullScreen, setupVideoEvents, onFullScreen} = this.props;
    return (
      <Container
        fullScreen={fullScreen}
        aside={['rest', 'done'].includes(animation)}
        // hoverable={!fullScreen && animation === 'done'}
      >
        <VideoPlayer
          visible={!!animation}
          fullScreen={fullScreen}
          ref={setupVideoEvents}
          onClick={onFullScreen}
          autoPlay={isMobile}
          controls={isMobile}
          // poster={poster}
          playsInline
          muted
          loop
        >
          <source
            src={isMobileOnly ? mobileVideoSrc : fullVideoSrc}
            // src={src}
            type="video/mp4"
          />
        </VideoPlayer>
        <Watch visible={['rest', 'done'].includes(animation)}>
          <Hamburger
            toggled={fullScreen}
            toggle={() => {}}
            onToggle={() => {}}
            size={20}
          />
          {fullScreen ? 'Close' : 'Watch'}
        </Watch>
        {/* {!isMobile && (
          <Overlay visible={!animation}>
            <Loader />
          </Overlay>
        )} */}
      </Container>
    );
  }
}

export default Video;
