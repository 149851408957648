import styled from 'styled-components';

const Button = styled.button`
  display: block;
  background: #ff3600;
  color: white;
  border: none;
  border-radius: 4px;
  height: 46px;
  padding: 0px 20px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }
`;

export default Button;
