import styled from 'styled-components';

const Privacy = styled.a`
  width: 100%;
  display: block;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: 'MavenPro', sans-serif;
  color: #263238;
`;

export default Privacy;
