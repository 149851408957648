import styled from 'styled-components';

const Links = styled.div`
  width: 100%;
  padding: 60px 50px;

  @media (max-width: 768px) {
    padding: 40px 20px;
    transform: none;
    position: static;
  }
`;

export default Links;
