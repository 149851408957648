import styled from 'styled-components';

const Title = styled.div`
  width: 100%;
  padding-bottom: 10px;
  color: #a4a4a4;
  text-align: center;
  border-bottom: 1px solid #ddd;
  position: relative;
  color: #000;
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export default Title;
