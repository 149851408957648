import styled from 'styled-components';

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export default ButtonContainer;
