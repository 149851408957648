import React, {Component} from 'react';
import styled from 'styled-components';
import {transitions} from 'polished';

const Container = styled.a`
  width: 100%;
  height: 50px;
  font-family: 'ProximaNova';
  font-weight: 300;
  letter-spacing: -0.02em;
  color: #263238;
  font-size: 24px;
  display: flex;
  align-items: center;
  position: relative;
  text-transform: uppercase;
  ${transitions(
    'transform 1.5s cubic-bezier(0.21, 0, 0.18, 1), opacity 1.5s cubic-bezier(0.21, 0, 0.18, 1)'
  )};

  @media (max-width: 1280px) {
    font-size: 22px;
  }

  @media (max-width: 768px) {
    color: #263238;
    height: 50px;
    font-size: 22px;
  }
`;

const Dot = styled.div`
  width: 16px;
  height: 4px;
  background: #ff3600;
  top: 50%;
  right: 100%;
  transform: ${({visible}) =>
    visible ? 'translate(-17px, -50%)' : 'translate(-50px, -50%)'};
  position: absolute;
  pointer-events: none;
  ${transitions('all .1s ease-in-out')};

  @media (max-width: 768px) {
    display: none;
  }
`;

class Link extends Component {
  state = {show: false, hover: false};

  componentDidMount() {
    const {visible} = this.props;
    if (visible) this.animate();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.visible && this.props.visible) this.animate();
  }

  animate = () => {
    const {pause} = this.props;
    setTimeout(() => {
      this.setState({show: true});
    }, pause);
  };

  hover = () => this.setState({hover: true});

  blur = () => this.setState({hover: false});

  render() {
    const {children, visible, pause, ...props} = this.props;
    const {show, hover} = this.state;
    return (
      <Container
        {...props}
        visible={show}
        hover={hover}
        onMouseEnter={this.hover}
        onMouseLeave={this.blur}
      >
        <Dot visible={hover} />
        {children}
      </Container>
    );
  }
}

export default Link;
